import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import SidebarInner from '@shared/ui/sidebars/SidebarInner';
import Button from '@shared/ui/buttons/Button';
import { IconContainer, StepContainer, StepTitle, SuccessIcon, } from '../shared';
import useConnectExchange from '../useConnectExchange';
const StepTitleStyled = styled(StepTitle)(() => ({
    textAlign: 'center',
    lineHeight: '32px',
}));
const SuccessReport = () => {
    const { t } = useTranslation();
    const { goToNextStep } = useConnectExchange();
    return (_jsx(SidebarInner, { children: _jsx(StepContainer, { content: (_jsxs(_Fragment, { children: [_jsx(IconContainer, { children: _jsx(SuccessIcon, {}) }), _jsx(StepTitleStyled, { children: t('overview.connect_exchange.success_report.title') })] })), buttons: (_jsx(Button, { size: 'large', fullWidth: true, onClick: () => goToNextStep(), children: t('overview.connect_exchange.success_report.continue_btn') })) }) }));
};
export default SuccessReport;
