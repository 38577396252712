import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { useForm } from 'react-hook-form';
import { useTheme } from '@mui/material';
import SidebarInner from '@shared/ui/sidebars/SidebarInner';
import SelectButton from '@shared/ui/buttons/SelectButton';
import Button from '@shared/ui/buttons/Button';
import { ReactComponent as ShieldIcon } from '@icons/wolfkit-light/shield-with-circle-light.svg';
import IconText from '@shared/ui/icons/IconText';
import Icon from '@shared/ui/icons/Icon';
import { Divider, StepFormContainer, StepText, StepTitle, } from '../shared';
import useConnectExchange from '../useConnectExchange';
import { BinanceIcon, BitmexIcon, CoinsferaIcon, IconContainer, } from '../icons';
const IconTextStyled = styled(IconText)(props => ({
    gap: props.theme.spacing_sizes.m,
    '& .MuiTypography-root': {
        color: props.theme.palette.text.primary,
    },
}));
const PaymentBannerContainer = styled.div(props => ({
    backgroundColor: '#F8FAFF',
    padding: `${props.theme.spacing_sizes.l}px ${props.theme.spacing_sizes.l}px 
              ${props.theme.spacing_sizes.l}px ${props.theme.spacing_sizes.xm}px`,
    borderRadius: 6,
}));
const BannerIconText = styled(IconTextStyled)(() => ({
    '& .MuiTypography-root': {
        whiteSpace: 'normal',
    },
}));
const Banner = () => {
    const { t } = useTranslation();
    return (_jsx(PaymentBannerContainer, { children: _jsx(BannerIconText, { IconComponent: (_jsx(Icon, { IconComponent: ShieldIcon, size: 32, keepOriginalColor: true })), text: t('overview.balance_management.payment_method.banner_text') }) }));
};
const SelectExchange = () => {
    const { t } = useTranslation();
    const theme = useTheme();
    const { sidebarFormValues, goToNextStep, handleClose } = useConnectExchange();
    const { watch, setValue, handleSubmit } = useForm({
        defaultValues: sidebarFormValues,
    });
    const exchange = watch('exchange');
    const handleExchangeSelect = (selectedOption) => {
        if (typeof selectedOption === 'string') {
            setValue('exchange', selectedOption);
        }
    };
    const onSubmit = (values) => {
        goToNextStep(values);
    };
    return (_jsx(SidebarInner, { padding: `${theme.spacing_sizes.xs * 6.25}px ${theme.spacing_sizes.s * 5}px`, cancelBtnProps: { onClick: handleClose }, displayCancelBtn: true, children: _jsx(StepFormContainer, { title: _jsx(StepTitle, { children: t('overview.connect_exchange.select_exchange.title') }), onSubmit: handleSubmit(onSubmit), content: (_jsxs(_Fragment, { children: [_jsx(StepText, { text: t('overview.connect_exchange.select_exchange.text'), stepNumber: t('overview.connect_exchange.select_exchange.step') }), _jsx(SelectButton, { options: [{
                                value: 'binance',
                                element: (_jsx(IconContainer, { children: _jsx(BinanceIcon, {}) })),
                            }], onChange: handleExchangeSelect, value: exchange || undefined, fullWidth: true }), _jsx(Divider, {}), _jsx(SelectButton, { options: [
                            {
                                value: 'bitmex',
                                element: (_jsx(IconContainer, { children: _jsx(BitmexIcon, {}) })),
                                disabled: true,
                            },
                            {
                                value: 'coinsfera',
                                element: (_jsx(IconContainer, { children: _jsx(CoinsferaIcon, {}) })),
                                disabled: true,
                            },
                        ], fullWidth: true })] })), contentJustify: 'start', contentFlex: 1, containerGap: theme.spacing_sizes.s * 10, buttons: (_jsxs(_Fragment, { children: [_jsx(Banner, {}), _jsx(Button, { size: 'large', type: 'submit', disabled: exchange === null, fullWidth: true, children: t('overview.connect_exchange.select_exchange.continue_btn') })] })), buttonsGap: theme.spacing_sizes.l }) }));
};
export default SelectExchange;
