import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import SidebarInner from '@shared/ui/sidebars/SidebarInner';
import { IconContainer, StepContainer, StepTitle, SuccessIcon, } from '../shared';
import useConnectExchange from '../useConnectExchange';
const Success = () => {
    const theme = useTheme();
    const { t } = useTranslation();
    const { goToNextStep } = useConnectExchange();
    return (_jsx(SidebarInner, { padding: `${theme.spacing_sizes.xs * 6.25}px ${theme.spacing_sizes.s * 5}px`, cancelBtnProps: { onClick: goToNextStep }, displayCancelBtn: true, children: _jsx(StepContainer, { content: (_jsxs(_Fragment, { children: [_jsx(IconContainer, { children: _jsx(SuccessIcon, {}) }), _jsx(StepTitle, { children: t('overview.connect_exchange.success.text') })] })), containerPadding: '0' }) }));
};
export default Success;
